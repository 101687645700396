import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

const Joel = () => {
  return (
    <Layout>
      <Head title="Joel Rivera" />
      <h3>Joel Rivera / PixemWeb</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        NYC
      </h4>
      <p>
        <OutboundLink href="https://www.pixemweb.com/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.youtube.com/c/Pixemweb">
          YouTube
        </OutboundLink>
      </p>
      <p>
        If you can SEE the INVISIBLE, You can do the IMPOSSIBLE! I Code, Play
        Chess, Workout, Blog & Vlog, and I’m all about Family!
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-1.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-2.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-3.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-4.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-5.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-6.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-7.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-8.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-9.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-10.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-11.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-12.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-13.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-14.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-15.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-16.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-17.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-18.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-19.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-20.jpg"
          alt="Joel Rivera video art"
        />
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1661973595/artnow/pixemweb/artnow-pixem-21.jpg"
          alt="Joel Rivera video art"
        />
      </div>
    </Layout>
  );
};

export default Joel;
